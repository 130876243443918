import * as Yup from "yup";

const nameRegex = /^[A-Za-z]+$/;

export const registerSchema = Yup.object({
  firstName: Yup.string()
  .min(3, "Name must be at least 3 characters")
  .max(25, "Name cannot exceed 25 characters")
  .matches(nameRegex, "first name can only contain letters, no spaces")
  .notOneOf(["null", "string"], "Invalid name")
  .required("Please enter your first name"),

lastName: Yup.string()
  .min(3, "Last name must be at least 3 characters")
  .max(25, "Last name cannot exceed 25 characters")
  .matches(nameRegex, "Last name can only contain letters, no spaces")
  .notOneOf(["null", "string"], "Invalid last name")
  .required("Please enter your last name"),
  email: Yup.string()
    .email("Enter a valid email address")
    .required("Please enter your email"),      
  password: Yup.string().min(8).required("Please enter your password")
});

export const loginSchema = Yup.object({
  email: Yup.string().email().required("Please enter email"),        
  password: Yup.string().min(8).required("Please enter your password")
});

export const subscribeSchema = Yup.object({
  email: Yup.string().email().required("Please enter email")
});
export const forgotPasswordSchema = Yup.object({
   otp: Yup.string().required("Please enter otp"),
   newPassword: Yup.string().min(8,'Password must be at least 8 characters').required('Password is required')

})


export const updateUserSchema = Yup.object({
  id: Yup.string().required(),
  firstName: Yup.string().required("Please enter your name"),
  lastName: Yup.string().required("Please enter last name"),
  profileBio: Yup.string().nullable().min(3, "Profile Bio must be at least three words").max(200),
  website: Yup.string().nullable().url('Enter a valid Website URL'),
  facebookProfileUrl: Yup.string().nullable().url('Enter a valid Facebook Profile URL'),
  linkedinProfileUrl: Yup.string().nullable().url('Enter a valid LinkedIn Profile URL'),
  twitterProfileUrl: Yup.string().nullable().url('Enter a valid Twitter Profile URL'),
  youtubeProfileUrl: Yup.string().nullable().url('Enter a valid Youtube Profile URL')
});

export const resetPwdSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Please Enter your password"),        
  newPassword: Yup.string().min(8,'Password must be at least 8 characters').required('Password is required').notOneOf([Yup.ref('oldPassword')],'New password must not be the same as old password'),
  confirmPassword: Yup.string().required('Confirm password is required').oneOf([Yup.ref('newPassword')],'Confirm Password and New Password must match'),
});

export const submitPostSchema = Yup.object({
  title: Yup.string().required("Please enter title of Blog"),
  blogMetaTag: Yup.string().min(60,"Meta Description must be at least 60 characters").required("Please enter some meta description"),
  metaKeyword: Yup.string().required("Please enter meta keyword"),
  altTag: Yup.string().required("Please enter AltTag "),
  blogData: Yup.string().required("Please enter editor content of Blog"),
  category: Yup.string().required('Please select blog category'),
  tags: Yup.array()
    .of(Yup.string())
    .required('Please select tags')
    .min(1, 'Please select minimum 1 tag'),
  aliasUrl : Yup.string()
    .when("alias", {
        is: (aCheckbox :boolean) => aCheckbox === true,
        then:()=> Yup.string()
          .required('Alias URL is required')
          .matches(/^[0-9A-Za-z-]*[0-9A-Za-z]$/, "Invalid alias URL")
      })
});

export const userQuerySchema = Yup.object({
  firstName: Yup.string().min(3).max(25).required("Please enter your first name"),
  lastName: Yup.string().min(3).max(25).required("Please enter your last name"),
  email: Yup.string().email().required("Please enter your email"),
  query: Yup.string().min(8).required("Please enter your query")
})