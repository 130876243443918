import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import { registerSchema as validationSchema } from "utils/validation";
import { SignupPayload } from "types/Auth";
import {
  ButtonPrimary,
  LayoutPage,
  NcLink,
  Input,
  NcModal
} from "components";
import { useAppDispatch } from "app/hooks";
import { signUpAction } from "app/auth/authSlice";
import SocialLogin from "containers/PageLogin/SocialLogin";

const PageSignUp: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [success, setSuccess] = useState<boolean>(false)
  const [errMessage, setErrMessage] = useState<string>('')
  const [loader, setLoader] = useState<boolean>(false);

  const initialValues: SignupPayload = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  };

  const onSubmit = (values: SignupPayload) => {
    setLoader(true);
      signUpAction(values)
      .then((res) =>history.push('/login', {email: values.email, isVerifyRequired: true}))
      .catch((err) =>{
        if(err.status == "503" || err.status == "504"){          
          setErrMessage("Having trouble connecting with server, Please try again after sometime")
        }else{
          setErrMessage(err.message)
        }
        setSuccess(true)
       
      })
      .finally(() => setLoader(false))
  };
  
  const formicForm = useFormik({ enableReinitialize: true, initialValues, validationSchema, onSubmit });

  return (
    <div className={`nc-PageSignUp`} data-nc-id="PageSignUp">
      <NcModal
        isOpenProp={success}
        modalTitle="Warning"
        contentExtraClass="max-w-screen-sm"
        renderTrigger={() => null}
        onCloseModal={() => {
          setSuccess(false);
        }}
        renderContent={() => 
          (<div>
            <p>{errMessage}</p>
          </div>
          )}
          />
      <Helmet>
        <title>Sign up- DZ Insights</title>
      </Helmet>
      <LayoutPage
        subHeading="Welcome to our blog magazine Community"
        headingEmoji="🎉"
        heading="Sign up"
      >
        <div className="max-w-md mx-auto space-y-6">
          <SocialLogin />
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <div className="grid grid-cols-1 gap-6">
            <Input
              disabled={loader}
              form={formicForm}
              name="firstName"
              placeholder="First Name"
              text="First Name"
              className="mt-1"
            />
            <Input
              disabled={loader}
              form={formicForm}
              name="lastName"
              placeholder="Last Name"
              text="Last Name"
              className="mt-1"
            />
            <Input
              disabled={loader}
              form={formicForm}
              placeholder="guest@example.com"
              name="email"
              text="Email"
              className="mt-1"
            />
            <Input
              disabled={loader}
              form={formicForm}
              placeholder="********"
              name="password"
              type="password"
              text="Password"
              className="mt-1"
            />
            <ButtonPrimary
              type="button"
              onClick={formicForm?.handleSubmit}
              loading={loader}
              disabled={loader}
            >
              Continue
            </ButtonPrimary>
          </div>
          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <NcLink aria-link="login page" to="/login">Sign in</NcLink>
          </span>
        </div>
      </LayoutPage>
    </div>
  );
};

export default PageSignUp;
